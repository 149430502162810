<script>
import { SuawMainContent, SuawSubheaderTwo, SuawSection, SuawCarouselRows, SuawDivider, SuawEmptyState, SuawSearchBar, SuawInputGroup } from "@suaw/suaw-component-library";
import BecomeOrganizer from "../../../common/components/BecomeOrganizer.vue";
import { ChapterDiscovery } from "../../operations.gql";
import { getGPS } from "../../../../utils/geo/location.js";
import chapterDiscoveryImage from "../../../../../public/img/home/chapter-discovery-image.svg";

export default {
  name: "ChapterDiscovery",
  components: {
    BecomeOrganizer,
    SuawMainContent,
    SuawSubheaderTwo,
    SuawSection,
    SuawCarouselRows,
    SuawDivider,
    SuawEmptyState,
    SuawSearchBar,
    SuawInputGroup
  },
  data() {
    return {
      chapterDiscoveryImage,
      chapterCards: [],
      //[99,999] is the fake global "place"
      searchAddress: undefined,
      searchLat: 99,
      searchLng: 999,
      searchDescription: "You"
    };
  },
  apollo: {
    chapterCards: {
      query: ChapterDiscovery,
      variables() {
        return {
          lat: this.searchLat,
          lng: this.searchLng,
          unitSystem: this.$auth.user ? this.$auth.user.unit_system : null //backend will determine from lat/lng when null
        };
      },
      skip() {
        return !this.isSearchReady;
      },
      update(data) {
        if (data && data.chapterDiscovery && data.chapterDiscovery.value && data.chapterDiscovery.value.length > 0) {
          const result = data.chapterDiscovery.value
            .filter(x => x.place.lat !== 99 && x.place.lng !== 999)
            .map(c => ({
              id: c.id,
              thumbnail: c.photoUrl,
              heading: c.title,
              slug: c.slug,
              meetupUrl: !c.activeAt && c.meetupSlug ? `https://meetup.com/${c.meetupSlug}` : null,
              chipItems: [
                { status: "default", icon: "IconLocation", label: `${c.place.city}, ${c.place.region}` },
                { status: "default", icon: "IconMap", label: `${Math.round(c.place.distance)} ${c.place.distanceUnits}` },
                { status: "info", icon: "IconUser", label: `${c.memberCount}` }
              ]
            }));
          return result;
        }
        return [];
      }
    }
  },
  computed: {
    isSearchReady() {
      return this.searchLat !== 99 && this.searchLng !== 999;
    },
    carouselHeading() {
      return `Chapters Near ${this.searchDescription}`;
    },
    showChapterResults() {
      return this.chapterCards.length > 0;
    }
  },
  created() {
    getGPS(position => {
      if (position !== null) {
        if (this.searchLat === 99 || this.searchLng == 999) {
          this.searchLat = position.lat;
          this.searchLng = position.lng;
        }
      }
    });
  },
  methods: {
    onPlaceSelected(place) {
      this.searchLat = place.geometry.location.lat();
      this.searchLng = place.geometry.location.lng();
      this.searchDescription = place.formatted_address;
    },
    onChapterClicked(chapterId) {
      const chapter = this.chapterCards.find(card => card.id === chapterId);
      if (chapter) {
        if (chapter.meetupUrl) {
          window.open(chapter.meetupUrl, "_blank");
        } else {
          this.$router.push({
            name: "Chapter",
            params: {
              chapterId: chapterId,
              chapterSlug: chapter.slug
            }
          });
        }
      } else {
        this.$log.error("Chapter not found!");
      }
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true" :small-top-margin="true">
    <SuawSubheaderTwo
      title="Chapters"
      description="Explore our global network of writing chapters and find one near you. Join a community, attend an event, and connect with fellow writers who will support you in your writing journey. Can't find an event that works for you? Become an organizer and create your own!"
      :image-source="chapterDiscoveryImage"
      image-alt="Two writers in discussion at a desk."
    />
    <SuawInputGroup direction="column" group-gap="triple">
      <SuawSearchBar
        placeholder="Enter your city, zip code, or address to find chapters near you"
        :show-location="false"
        :use-google="true"
        :cities-only="false"
        @submit="onPlaceSelected"
      />
      <SuawEmptyState v-if="!showChapterResults" message="Finding your location to search for chapters near you. You may also use the search bar above." icon="IconSearch" />
      <SuawCarouselRows v-else :carousel-heading="carouselHeading" card-type="CardChapter" :cards="chapterCards" @click="onChapterClicked" />
    </SuawInputGroup>
    <SuawSection>
      <SuawDivider />
    </SuawSection>
    <SuawSection>
      <BecomeOrganizer />
    </SuawSection>
  </SuawMainContent>
</template>
